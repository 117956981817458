import { useCallback, useEffect, useState } from 'react'
import useMount from 'react-use/lib/useMount'

import { AnalyticsActions } from 'store/Analytics'
import { useAppDispatch } from 'store/hooks'

import { AnalyticsEvents } from '.'
import events from './events'
import type { Payload } from 'store/Analytics/types'
import type { ItemPreparationStatuses } from 'store/Orders/enums'
import type { Order, OrderItem } from 'store/Orders/types'

type ScreenName = 'chef' | 'overview' | 'itemDetails' | 'settings' | 'crash'

const getEventPayload = (screenName: ScreenName) => {
  switch (screenName) {
    case 'chef':
      return events.chefScreenEntered()
    case 'overview':
      return events.overviewScreenEntered()
    case 'itemDetails':
      return events.itemDetailsScreenEntered()
    case 'settings':
      return events.settingsScreenEntered()
    case 'crash':
      return events.crashScreenEntered()
  }
}

export const useEnterScreen = (screenName: ScreenName) => {
  const dispatch = useAppDispatch()

  useMount(() => {
    const eventPayload = getEventPayload(screenName)

    if (!eventPayload) return

    dispatch(AnalyticsActions.logEvent(eventPayload))
  })
}

export const useLogEventCallback = () => {
  const dispatch = useAppDispatch()

  return useCallback(
    (payload: Payload) => {
      dispatch(AnalyticsActions.logEvent(payload))
    },
    [dispatch],
  )
}

type ItemStatusChangedUiUpdateData = {
  orderId: string
  itemId: string
  mode: 'single' | 'group'
  startDate: Date
  endDate: Date
  status: ItemPreparationStatuses
  screen: string | undefined
}

export const useLogItemStatusChangedUiUpdate = (items: OrderItem[]) => {
  const [updatedItemList, setUpdatedItemList] = useState<
    ItemStatusChangedUiUpdateData[]
  >([])

  const logEvent = useLogEventCallback()

  useEffect(() => {
    if (updatedItemList.length <= 0) return

    for (const value of updatedItemList) {
      const { endDate, mode, itemId, orderId, screen, startDate, status } =
        value

      const selectedItem = items?.find((item) => item?.uuid === itemId)

      if (selectedItem === undefined || selectedItem.status === value.status) {
        setUpdatedItemList((prev) => {
          return prev.filter((list) => list.itemId !== itemId)
        })

        const now = new Date()

        logEvent(
          AnalyticsEvents.itemStatusChangedUiUpdate({
            orderId: orderId,
            itemId: itemId,
            mode: mode,
            requestStartDate: startDate.toISOString(),
            requestEndDate: endDate.toISOString(),
            uiInteractionEndDate: now.toISOString(),
            fullExecutionTime: now.getTime() - startDate.getTime(),
            uiResponseTime: now.getTime() - endDate.getTime(),
            requestExecutionTime: endDate.getTime() - startDate.getTime(),
            status: status,
            screen,
          }),
        )
      }
    }
  }, [items, updatedItemList])

  const addItemToUpdatedItemList = useCallback(
    (props: ItemStatusChangedUiUpdateData) => {
      setUpdatedItemList((prev) => [...prev, props])
    },
    [],
  )

  return {
    addItemToUpdatedItemList,
  }
}
