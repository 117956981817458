import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { useSelector } from 'react-redux'
import { capitalize, isEmpty } from 'lodash'
import { DateTime } from 'luxon'
import { MaterialCommunityIcons } from '@expo/vector-icons'

import colors from 'config/colors'
import {
  DeliveryStatusBadge,
  DriverAvatar,
  OrderStartPreparationStatusBadge,
  Text,
  VerticalDivider,
  WebImage,
} from 'components'
import {
  ActionButtonOrder,
  HasUnacceptChangesLabel,
} from 'modules/ChefScene/components/ActionButton'
import { RouteNames } from 'navigation/linkingConfig'
import { NotificationsSelectors } from 'store/Notifications'
import {
  ItemPreparationStatuses,
  OrderDeliveryChannels,
  OrderInternalStatuses,
} from 'store/Orders/enums'
import { checkIfScheduledOrder } from 'store/Orders/utils'
import { SettingSelectors } from 'store/Settings'
import { UserSelectors } from 'store/User'
import { AnalyticsEvents, useLogEventCallback } from 'util/analytics'
import {
  getAddressLine,
  getColorForOrderStatus,
  getIconNameForOrderKind,
  getLabelKeyForOrderKind,
  isWeb,
  useFormatDateTime,
} from 'util/helpers'
import { useCurrencyString } from 'util/helpers/currency'
import { useSumOrderItems } from 'util/hooks'

import { RemakeOrderDescription } from './RemakeOrderDescription'
import type { FinancialInfo, Order, OrderItem } from 'store/Orders/types'
import type { MaterialCommunityIconName } from 'types/icons'

type OrderDetailsHeaderFieldProps = {
  label?: React.ReactNode
  value?: React.ReactNode
}

const OrderDetailsHeaderField = ({
  label,
  value,
}: OrderDetailsHeaderFieldProps) => {
  return (
    <View style={styles.field}>
      {Boolean(label) && (
        <Text style={styles.fieldLabel} color="white" font="medium" size={14}>
          {label}:{' '}
        </Text>
      )}
      {Boolean(value) && (
        <Text style={styles.fieldLabel} color="white" font="medium" size={14}>
          {value}
        </Text>
      )}
    </View>
  )
}

const OrderDetailsSubHeaderField = ({
  label,
  icon,
  iconName,
}: OrderDetailsHeaderFieldProps &
  RequireAtLeastOne<{
    icon: React.ReactNode
    iconName: MaterialCommunityIconName
  }>) => {
  return (
    <View style={[styles.field, { marginTop: 4 }]}>
      <View style={styles.iconContainer}>
        {icon ? (
          icon
        ) : (
          <MaterialCommunityIcons color="white" name={iconName} size={28} />
        )}
      </View>
      {Boolean(label) && (
        <Text style={styles.fieldLabel} color="white" font="bold" size={14}>
          {label}
        </Text>
      )}
    </View>
  )
}

const OrderDetailsSubHeaderFieldAlternate = ({
  label,
  value,
}: OrderDetailsHeaderFieldProps) => {
  return (
    <View style={styles.fieldAlternate}>
      {Boolean(label) && (
        <Text
          style={styles.fieldLabel}
          color={colors.lightGray}
          font="medium"
          size={14}
        >
          {label}:
        </Text>
      )}
      {Boolean(value) && (
        <Text
          style={styles.fieldLabel}
          color="white"
          font="medium"
          size={14}
          numberOfLines={2}
        >
          {value}
        </Text>
      )}
    </View>
  )
}

type FinancialInfoProps = {
  financialInfo: FinancialInfo
}

export const FinancialInfoSection = ({ financialInfo }: FinancialInfoProps) => {
  const { t } = useTranslation()

  const getCurrencyString = useCurrencyString()

  return (
    <View
      style={{
        padding: 8,
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: 1,
        minWidth: 250,
        alignSelf: 'stretch',
      }}
    >
      <View
        style={[
          styles.subHeader,
          {
            borderRadius: 8,
            backgroundColor: 'black',
            alignSelf: 'stretch',
            paddingVertical: 8,
          },
        ]}
      >
        <View style={styles.column}>
          <OrderDetailsSubHeaderFieldAlternate
            label={t('overview.orderDetails.itemSubtotal')}
            value={getCurrencyString(financialInfo.items_subtotal)}
          />
          <OrderDetailsSubHeaderFieldAlternate
            label={t('overview.orderDetails.deposit')}
            value={getCurrencyString(financialInfo.deposit)}
          />
        </View>
        <VerticalDivider color={'white'} />
        <View style={styles.column}>
          <OrderDetailsSubHeaderFieldAlternate
            label={t('overview.orderDetails.deliveryFee')}
            value={getCurrencyString(financialInfo.delivery_fee)}
          />
          <OrderDetailsSubHeaderFieldAlternate
            label={t('overview.orderDetails.discount')}
            value={getCurrencyString(financialInfo.discount)}
          />
        </View>
        <VerticalDivider color={'white'} />
        <View style={styles.column}>
          <OrderDetailsSubHeaderFieldAlternate
            label={t('overview.orderDetails.credit')}
            value={getCurrencyString(financialInfo.credit)}
          />
          <OrderDetailsSubHeaderFieldAlternate
            label={t('overview.orderDetails.tax')}
            value={getCurrencyString(financialInfo.tax)}
          />
        </View>
        <VerticalDivider color={'white'} />
        <View style={styles.column}>
          <OrderDetailsSubHeaderFieldAlternate
            label={t('overview.orderDetails.tip')}
            value={getCurrencyString(financialInfo.total_tip_left_for_driver)}
          />
          <OrderDetailsSubHeaderFieldAlternate
            label={t('overview.orderDetails.total')}
            value={getCurrencyString(financialInfo.grand_total_including_tax)}
          />
        </View>
      </View>
    </View>
  )
}

type OrderDetailsHeaderProps = {
  order: Order
  items: OrderItem[]
  orderInternalStatus: OrderInternalStatuses
  onStatusChanged?: (props: {
    orderId: string
    itemId: string
    mode: 'single' | 'group'
    startDate: Date
    endDate: Date
    status: ItemPreparationStatuses
    screen: string | undefined
  }) => void
}

export const OrderDetailsListHeader = ({ order }: OrderDetailsHeaderProps) => {
  const { t } = useTranslation()

  const featureFlags = useSelector(UserSelectors.selectFeatureFlags)
  const interfaceSettings = useSelector(UserSelectors.selectInterfaceSettings)

  const { recipient, financial_info: financialInfo } = order

  const {
    shouldDisplayFinancialInfo,
    shouldDisplayCustomerAddress,
    shouldDisplayCustomFields,
  } = useSelector(SettingSelectors.selectOrderDetailsInterfaceSettings)

  const isShowCustomerSection =
    shouldDisplayCustomerAddress && featureFlags.display_customer_address
  const isShowFinancialSection =
    shouldDisplayFinancialInfo && featureFlags.display_financial_info
  const isShowCustomFields =
    shouldDisplayCustomFields &&
    interfaceSettings.order_details_card.kds_custom_fields

  if (
    !isShowCustomerSection &&
    !isShowFinancialSection &&
    !isShowCustomFields
  ) {
    return null
  }

  return (
    <View
      style={[
        styles.container,
        {
          backgroundColor: colors.darkGray,
          flexWrap: 'wrap',
        },
      ]}
    >
      <View style={styles.subHeader}>
        <View
          style={[
            styles.subHeader,
            {
              margin: 8,
              marginHorizontal: 0,
              flexBasis: 1,
              minWidth: 250,
            },
          ]}
        >
          {isShowCustomerSection && (
            <View style={styles.column}>
              <OrderDetailsSubHeaderFieldAlternate
                label={t('overview.orderDetails.customer')}
                value={recipient.full_name}
              />
              <OrderDetailsSubHeaderFieldAlternate
                label={t('overview.orderDetails.phone')}
                value={recipient.phone_number}
              />
              {order.dropoff_location && (
                <OrderDetailsSubHeaderFieldAlternate
                  label={t('overview.orderDetails.address')}
                  value={getAddressLine(order.dropoff_location)}
                />
              )}
            </View>
          )}
          {isShowCustomFields && !isEmpty(order.kds_custom_fields) && (
            <View style={styles.column}>
              {order.kds_custom_fields.map(({ key, value }, index) => {
                return (
                  <OrderDetailsSubHeaderFieldAlternate
                    key={`${key}-${index}`}
                    label={capitalize(key)}
                    value={value}
                  />
                )
              })}
            </View>
          )}
        </View>
        {isShowFinancialSection && (
          <FinancialInfoSection financialInfo={financialInfo} />
        )}
      </View>
    </View>
  )
}

export const OrderDetailsHeader = ({
  order,
  items,
  orderInternalStatus,
  onStatusChanged,
}: OrderDetailsHeaderProps) => {
  const logEvent = useLogEventCallback()
  const { t } = useTranslation()
  const formatDateTime = useFormatDateTime()

  const isScheduledFutureOrder = checkIfScheduledOrder(order)

  const hasUnacceptedNotifications = useSelector((state) =>
    // @ts-ignore
    NotificationsSelectors.selectOrderHasUnattended(state, order.uuid),
  )
  const {
    shouldDisplayActionButtonHeader,
    shouldDisplayDriverDetails,
    shouldDisplayOrderChannel,
  } = useSelector(SettingSelectors.selectOrderDetailsInterfaceSettings)

  const featureFlags = useSelector(UserSelectors.selectFeatureFlags)

  const headerColor = getColorForOrderStatus(orderInternalStatus)

  const itemCount = useSumOrderItems(items)

  const { channel, assigned_driver: assignedDriver } = order

  const showActionButton =
    orderInternalStatus !== OrderInternalStatuses.CANCELLED &&
    (hasUnacceptedNotifications ||
      (shouldDisplayActionButtonHeader &&
        orderInternalStatus !== OrderInternalStatuses.PREPARED))

  const _formatDateTime = (date: string) => {
    return `${formatDateTime(date, DateTime.TIME_SIMPLE)} - ${formatDateTime(
      date,
      {
        month: 'short',
        day: 'numeric',
      },
    )}`
  }

  const getStatusChangeCallback =
    (params?: any) =>
    (
      statusToUpdate: ItemPreparationStatuses,
      itemsToUpdate: OrderItem[],
      duration: number,
      startDate: Date,
      endDate: Date,
    ) => {
      logEvent(
        AnalyticsEvents.itemStatusChanged({
          isScheduled: isScheduledFutureOrder,
          screen: RouteNames.Home.Main.Drawer.Tabs.Overview.OrderDetails,
          status: statusToUpdate,
          orderId: order.uuid,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
          executionTime: duration,
          ...params,
        }),
      )

      for (const item of itemsToUpdate) {
        onStatusChanged?.({
          startDate,
          endDate,
          status: statusToUpdate,
          itemId: item.uuid,
          mode: 'group',
          orderId: item.order_uuid,
          screen: RouteNames.Home.Main.Drawer.Tabs.Overview.OrderDetails,
        })
      }
    }

  return (
    <View style={styles.container}>
      <View
        style={[
          styles.header,
          {
            backgroundColor: headerColor,
          },
        ]}
      >
        <View
          style={{
            flexDirection: 'column',
            ...(isWeb
              ? {
                  flex: 4,
                }
              : {
                  flexGrow: 4,
                }),
            justifyContent: 'space-between',
            minWidth: 200,
            marginHorizontal: 12,
          }}
        >
          <View
            style={[
              styles.row,
              {
                alignItems: 'flex-start',
              },
            ]}
          >
            <Text font="black" size={20} color="white">
              #{order.description}{' '}
              {order.remake_for_order_uuid &&
                order.remake_for_order_description && (
                  <RemakeOrderDescription
                    description={order.remake_for_order_description}
                    color={colors.brightBeige}
                  />
                )}
            </Text>
            <View>
              {featureFlags.kds_enabled_traffic_light_system &&
                orderInternalStatus ===
                  OrderInternalStatuses.NOT_BEING_PREPARED && (
                  <OrderStartPreparationStatusBadge
                    status={order.start_preparation_status}
                  />
                )}
              {orderInternalStatus !== OrderInternalStatuses.CANCELLED &&
                order.job &&
                orderInternalStatus === OrderInternalStatuses.PREPARED && (
                  <DeliveryStatusBadge
                    status={order.job.delivery_status}
                    geoVerified={order.job.geo_verified}
                  />
                )}
            </View>
          </View>

          <View
            style={{
              paddingVertical: 4,
            }}
          >
            <OrderDetailsHeaderField
              label={t('overview.orderDetails.placedAt')}
              value={_formatDateTime(order.created_at)}
            />
            {Boolean(order.scheduled_for) && (
              <OrderDetailsHeaderField
                label={t('overview.orderDetails.scheduledFor')}
                value={_formatDateTime(order.scheduled_for)}
              />
            )}
            <OrderDetailsHeaderField
              label={t('overview.orderDetails.items')}
              value={itemCount}
            />
          </View>
        </View>
        <VerticalDivider color={'white'} style={styles.divider} />
        <View
          style={[
            styles.headerColumn,
            {
              ...(isWeb
                ? {
                    flex: showActionButton ? 5 : 8,
                  }
                : {
                    flexGrow: showActionButton ? 5 : 8,
                  }),
              minWidth: 200,
            },
          ]}
        >
          <View style={styles.subHeader}>
            <OrderDetailsSubHeaderField
              iconName={getIconNameForOrderKind(order.kind)}
              label={t(getLabelKeyForOrderKind(order.kind))}
            />
            {shouldDisplayOrderChannel &&
              channel.slug !== OrderDeliveryChannels.UNKNOWN && (
                <OrderDetailsSubHeaderField
                  icon={
                    <WebImage
                      uri={channel.logo_cloudinary_url}
                      width={40}
                      height={40}
                      shape={'rect'}
                      borderRadius={5}
                    />
                  }
                  label={channel.name}
                />
              )}
            {shouldDisplayDriverDetails &&
              featureFlags.display_driver_details &&
              assignedDriver && (
                <OrderDetailsSubHeaderField
                  icon={
                    <DriverAvatar
                      uri={assignedDriver.profile_picture_url}
                      fullName={assignedDriver.full_name}
                      size={36}
                      style={{
                        borderRadius: 4,
                        borderColor: '#fff',
                        borderWidth: 1,
                        alignSelf: 'flex-end',
                      }}
                    />
                  }
                  label={assignedDriver.full_name}
                />
              )}
          </View>
        </View>
        {showActionButton && (
          <>
            <VerticalDivider color={'white'} style={styles.divider} />
            <View
              style={[
                styles.headerColumn,
                {
                  marginVertical: 8,
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  minWidth: 200,
                  ...(isWeb
                    ? {
                        flex: 3,
                      }
                    : {
                        flexGrow: 3,
                      }),
                },
              ]}
            >
              {hasUnacceptedNotifications ? (
                <HasUnacceptChangesLabel color={'white'} />
              ) : shouldDisplayActionButtonHeader &&
                orderInternalStatus !== OrderInternalStatuses.PREPARED ? (
                <ActionButtonOrder
                  includePrintButton
                  order={order}
                  items={items}
                  isScheduled={isScheduledFutureOrder}
                  onPressFinish={getStatusChangeCallback({ print: false })}
                  onPressFinishAndPrint={getStatusChangeCallback({
                    print: true,
                  })}
                />
              ) : null}
            </View>
          </>
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'stretch',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 8,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    flexWrap: 'wrap',
  },
  divider: {
    opacity: 0.5,
  },
  subHeader: {
    flexGrow: 1,
    flexShrink: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  headerColumn: {
    marginHorizontal: 12,
  },
  column: {
    flex: 1,
    minWidth: 60,
    paddingHorizontal: 12,
  },
  row: {
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    paddingVertical: 4,
  },
  iconContainer: {
    width: 36,
    height: 36,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'black',
    borderRadius: 4,
    marginRight: 8,
  },
  field: {
    flexGrow: 1,
    flexShrink: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  fieldAlternate: {
    marginTop: 4,
    alignItems: 'flex-start',
  },
  fieldLabel: {
    flexWrap: 'wrap',
    ...(isWeb ? { wordBreak: 'break-all' } : {}),
  },
})

export default OrderDetailsHeader
