import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SectionList, StyleSheet, TouchableOpacity, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useSelector } from 'react-redux'
import _, { isEmpty } from 'lodash'
import { MaterialCommunityIcons } from '@expo/vector-icons'
import { useNavigation } from '@react-navigation/native'

import colors from 'config/colors'
import { DEFAULT_ACTIVE_OPACITY } from 'config/constants'
import { CancelledLabel, Spacer, Text, withErrorBoundary } from 'components'
import {
  ActionButtonOrder,
  HasUnacceptChangesLabel,
  ReprintButtonOrder,
} from 'modules/ChefScene/components/ActionButton'
import {
  AttendedList,
  UnattendedList,
} from 'modules/OverviewScene/NotificationsLog'
import {
  useOrderDetailsStateContext,
  withOrderDetailsStateProvider,
} from 'modules/OverviewScene/OrderDetailsStateContext'
import { RouteNames } from 'navigation/linkingConfig'
import { NotificationsSelectors } from 'store/Notifications'
import { OrderSelectors } from 'store/Orders'
import {
  ItemPreparationStatuses,
  OrderInternalStatuses,
  OrderStatuses,
} from 'store/Orders/enums'
import {
  checkIfScheduledOrder,
  getOrderInternalStatus,
} from 'store/Orders/utils'
import { SettingSelectors } from 'store/Settings'
import { UserSelectors } from 'store/User'
import {
  AnalyticsEvents,
  useLogEventCallback,
  useLogItemStatusChangedUiUpdate,
} from 'util/analytics'
import { isPermutation } from 'util/helpers'

import {
  OrderCellError,
  OrderDetailItem,
  OrderDetailSectionHeader,
  OrderDetailsHeader,
  OrderDetailsListHeader,
  OrderNotes,
} from './components'
import type { Order, OrderItem } from 'store/Orders/types'

type NonCookableActionButtonProps = {
  order: Order
  items: OrderItem[]
  onStatusChanged?: (props: {
    orderId: string
    itemId: string
    mode: 'single' | 'group'
    startDate: Date
    endDate: Date
    status: ItemPreparationStatuses
    screen: string | undefined
  }) => void
}

function NonCookableActionButton({
  order,
  items,
  onStatusChanged,
}: NonCookableActionButtonProps) {
  const logEvent = useLogEventCallback()

  const featureFlags = useSelector(UserSelectors.selectFeatureFlags)
  const { shouldDisplayActionButtonItems } = useSelector(
    SettingSelectors.selectOrderDetailsInterfaceSettings,
  )

  const getStatusChangeCallback =
    (params?: any) =>
    (
      statusToUpdate: ItemPreparationStatuses,
      itemsToUpdate: OrderItem[],
      duration: number,
      startDate: Date,
      endDate: Date,
    ) => {
      const isScheduled = checkIfScheduledOrder(order)

      logEvent(
        AnalyticsEvents.itemStatusChanged({
          isScheduled: isScheduled,
          screen: RouteNames.Home.Main.Drawer.Tabs.Overview.OrderDetails,
          status: statusToUpdate,
          orderId: order.uuid,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
          executionTime: duration,
          ...params,
        }),
      )

      for (const item of itemsToUpdate) {
        onStatusChanged?.({
          mode: 'group',
          startDate,
          endDate,
          status: statusToUpdate,
          itemId: item.uuid,
          orderId: item.order_uuid,
          screen: RouteNames.Home.Main.Drawer.Tabs.Overview.OrderDetails,
        })
      }
    }

  const nonCookableItems = useMemo(() => {
    return items.filter((item) => !item.cookable)
  }, [items])

  const orderInternalStatus = useMemo(() => {
    return getOrderInternalStatus(order, nonCookableItems)
  }, [nonCookableItems, order])

  const hasUnacceptedNotifications = useSelector((state) =>
    // @ts-ignore
    NotificationsSelectors.selectItemsHasUnattended(state, nonCookableItems),
  )

  if (!shouldDisplayActionButtonItems) return null

  return (
    <View style={styles.nonCookablesButtons}>
      {hasUnacceptedNotifications ? (
        <HasUnacceptChangesLabel color="white" style={{ paddingLeft: 8 }} />
      ) : orderInternalStatus ===
        OrderInternalStatuses.CANCELLED ? null : orderInternalStatus !==
        OrderInternalStatuses.PREPARED ? (
        <ActionButtonOrder
          includePrintButton
          order={order}
          items={nonCookableItems}
          isScheduled={checkIfScheduledOrder(order)}
          onPressFinish={getStatusChangeCallback({ print: false })}
          onPressFinishAndPrint={getStatusChangeCallback({
            print: true,
          })}
        />
      ) : featureFlags.display_finish_and_print_button ? (
        <ReprintButtonOrder
          order={order}
          itemUUIDs={nonCookableItems.map((item) => item.uuid)}
          onPress={() => {
            logEvent(
              AnalyticsEvents.reprintButtonPressed({
                screen: RouteNames.Home.Main.Drawer.Tabs.Overview.OrderDetails,
              }),
            )
          }}
        />
      ) : null}
    </View>
  )
}

function CellRenderer({ style: styleProp, children, ...props }: any) {
  const { contentContainerStyle, header } = props.item?.options ?? {}

  return (
    <View style={[styleProp, contentContainerStyle]} {...props}>
      {children}
      {/header/gi.test(props.cellKey) && header}
    </View>
  )
}

type OrderDetailsComponentProps = {
  items: OrderItem[]
  order: Order
  onStatusChanged?: (props: {
    orderId: string
    itemId: string
    mode: 'single' | 'group'
    startDate: Date
    endDate: Date
    status: ItemPreparationStatuses
    screen: string | undefined
  }) => void
}

type Section = {
  name: string
  nameKey?: string
  data: OrderItem[]
  options?: any
  isLast?: boolean
}

const OrderDetailsComponent = (props: OrderDetailsComponentProps) => {
  const { items, order, onStatusChanged } = props
  const { orderInternalStatus } = useOrderDetailsStateContext()
  const { t } = useTranslation()

  const sections = useMemo(() => {
    const cookablesAndNonCookables = _.groupBy(items, 'cookable')

    const groupedCookablesItems = _.groupBy(
      cookablesAndNonCookables.true,
      'category',
    )
    const groupedNonCookablesItems = _.groupBy(
      cookablesAndNonCookables.false,
      'category',
    )

    const mapFunction = (value: OrderItem[], key: string): Section => ({
      name: key,
      data: value,
    })

    const sections = [
      ..._.map(groupedCookablesItems, mapFunction),
      ...(!isEmpty(groupedNonCookablesItems)
        ? [
            {
              name: t('overview.orderDetails.nonCookables'),
              data: [],
              options: {
                textSize: 18,
                style: styles.nonCookablesHeader,
                contentContainerStyle: styles.nonCookeblesCellContainer,
                header: (
                  <NonCookableActionButton
                    order={order}
                    items={items}
                    onStatusChanged={onStatusChanged}
                  />
                ),
              },
            },
            ..._.map(groupedNonCookablesItems, mapFunction),
          ]
        : []),
    ]

    const lastSection = sections.at(-1)

    sections[sections.length - 1] = { ...lastSection, isLast: true }

    return sections as Section[]
  }, [items])

  return (
    <View style={[styles.content, { flex: 1 }]}>
      <OrderDetailsHeader
        order={order}
        items={items}
        orderInternalStatus={orderInternalStatus}
        onStatusChanged={onStatusChanged}
      />
      <OrderNotes
        specialInstructions={order.special_instructions}
        internalNotes={order.internal_notes}
      />
      {order.order_status === OrderStatuses.CANCELLED && <CancelledLabel />}
      <View style={styles.itemContainer}>
        <SectionList
          CellRendererComponent={CellRenderer}
          ListHeaderComponent={
            <>
              <OrderDetailsListHeader
                order={order}
                items={items}
                orderInternalStatus={orderInternalStatus}
              />
              <View style={{ backgroundColor: 'white' }}>
                <UnattendedList orderUUID={order.uuid} />
              </View>
            </>
          }
          sections={sections}
          scrollEnabled={true}
          renderItem={({ item, index, section }) => {
            return (
              <>
                {index === 0 && (
                  <OrderDetailSectionHeader
                    name={section.name}
                    {...section.options}
                  />
                )}
                <OrderDetailItem
                  order={order}
                  item={item}
                  index={index}
                  shouldDifferentiateLayout={section.data.length > 1}
                  orderInternalStatus={orderInternalStatus}
                  onStatusChanged={onStatusChanged}
                />
              </>
            )
          }}
          keyExtractor={(item) => item.uuid}
          renderSectionHeader={({ section }) => {
            if (!section.options?.header) return null

            return (
              <OrderDetailSectionHeader
                name={section.name}
                {...section.options}
              />
            )
          }}
          renderSectionFooter={({ section }) => {
            if (!section.isLast) return null

            return <AttendedList orderUUID={order.uuid} />
          }}
        />
      </View>
    </View>
  )
}

type OrderDetailsProps = {
  order: Order | null
}
const OrderDetails = ({ order }: OrderDetailsProps) => {
  const { t } = useTranslation()

  const { uuid } = order ?? {}

  const navigation = useNavigation()
  const { setSelectedUUID } = useOrderDetailsStateContext()

  const [isShowUnselectedCategories, setShowUnselectedCategories] =
    useState(false)

  const allCategories = useSelector(SettingSelectors.selectAllCategories)
  const selectedCategories = useSelector(
    SettingSelectors.selectSelectedCategories,
  )
  const isSelectedAllCategories = useSelector(
    SettingSelectors.selectIsSelectedAllCategories,
  )

  const filteredItems = useSelector((state) =>
    uuid && order
      ? OrderSelectors.selectItemsForOrderWithCategories(state, uuid)
      : null,
  )

  const allItems = useSelector((state) =>
    uuid && order ? OrderSelectors.selectItemsForOrder(state, uuid) : null,
  )

  useEffect(() => {
    if (uuid && order) {
      navigation.setOptions({
        title: t('overview.titleWithSelectedOrder', {
          orderId: order.description,
        }),
      })
    }
  }, [uuid, order])

  useEffect(() => {
    setShowUnselectedCategories(false)
  }, [uuid])

  const items = isShowUnselectedCategories ? allItems : filteredItems

  const { addItemToUpdatedItemList } = useLogItemStatusChangedUiUpdate(items)

  ///

  if (!uuid || !order || !items || !items.length) {
    return (
      <View
        style={{ backgroundColor: colors.lightBlue2, flex: 1, padding: 30 }}
      >
        <Text
          color="white"
          size={18}
          align={'center'}
          font={'medium'}
          id={'overview.orderDetails.placeholder'}
        />
      </View>
    )
  } else {
    return (
      <>
        <View
          style={{
            flexDirection: 'row',
          }}
        >
          {allItems.length !== filteredItems.length &&
          !isSelectedAllCategories &&
          !isPermutation(allCategories, selectedCategories) ? (
            <TouchableOpacity
              activeOpacity={DEFAULT_ACTIVE_OPACITY}
              onPress={() => setShowUnselectedCategories((current) => !current)}
              style={{
                padding: 8,
                flexGrow: 1,
              }}
            >
              <Text
                color={'white'}
                align={'center'}
                font={'medium'}
                size={14}
                id={
                  isShowUnselectedCategories
                    ? 'overview.orderDetails.hideHiddenItemsLabel'
                    : 'overview.orderDetails.showHiddenItemsLabel'
                }
              />
            </TouchableOpacity>
          ) : (
            <Spacer />
          )}
          <TouchableOpacity
            onPress={() => setSelectedUUID(null)}
            activeOpacity={DEFAULT_ACTIVE_OPACITY}
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              padding: 8,
              justifyContent: 'center',
              alignSelf: 'flex-end',
            }}
          >
            <MaterialCommunityIcons
              name="window-close"
              size={20}
              color="white"
            />
          </TouchableOpacity>
        </View>
        <OrderDetailsComponent
          order={order}
          items={items}
          onStatusChanged={({
            endDate,
            itemId,
            orderId,
            startDate,
            status,
            mode,
            screen,
          }) => {
            addItemToUpdatedItemList({
              endDate,
              itemId,
              orderId,
              startDate,
              status,
              mode,
              screen,
            })
          }}
        />
      </>
    )
  }
}

const OrderDetailsWithErrorBoundary = withErrorBoundary(OrderDetails, {
  screen: RouteNames.Home.Main.Drawer.Tabs.Overview._,
  key: 'overview-details',
  renderFallback:
    (props: any) =>
    ({ resetError }) =>
      (
        <OrderCellError
          {...props}
          style={styles.content}
          resetError={resetError}
        />
      ),
})

const OrderDetailContainer = () => {
  const { selectedUUID } = useOrderDetailsStateContext()

  const order = useSelector((state) =>
    selectedUUID ? OrderSelectors.selectById(state, selectedUUID) : null,
  )

  return (
    <SafeAreaView style={styles.container}>
      <OrderDetailsWithErrorBoundary order={order} />
    </SafeAreaView>
  )
}

const OrderDetailsContainerWithContext =
  withOrderDetailsStateProvider(OrderDetailContainer)

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.lightBlue2,
    paddingTop: 12,
    paddingRight: 16,
  },
  content: {
    marginBottom: 12,
  },
  header: {
    backgroundColor: '#00A87C',
    minHeight: 120,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  itemContainer: {
    flexGrow: 1,
    flexShrink: 1,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    overflow: 'hidden',
  },
  nonCookeblesCellContainer: { zIndex: 10 },
  nonCookablesHeader: {
    backgroundColor: colors.darkGray,
    paddingVertical: 12,
    zIndex: 10,
  },
  nonCookablesButtons: {
    position: 'absolute',
    padding: 8,
    paddingTop: 0,
    paddingLeft: 0,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    top: 8,
    right: 4,
    backgroundColor: colors.darkGray,
    overflow: 'hidden',
    zIndex: 100,
  },
})

export default OrderDetailsContainerWithContext
